"use client";
import Cart from "@/common/constants/cart";
import {
	setCartGlobalErrors,
	setCartDetail,
	setProductOutOfStockInCart,
	setCartLocalId,
	setCartLoading,
} from "@/redux/features/CartSlice";
import {
	setBrowserId,
	setPopupId,
	setReload,
} from "@/redux/features/ShopSlice";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import {
	CartAddEditJson,
	CartCouponAddJson,
	CartId,
	CartJson,
	CartOrderJson,
	CartPayJson,
	CartPreOrderAddEditJson,
	CartPromotion,
	CartUpdateCustomer,
	CartUpdateDiscount,
	IsUse,
	ProductCartAddEditJson,
} from "@/types/Cart.type";
import { CouponCheckJson } from "@/types/Coupon.type";

import { ProductJson } from "@/types/Product.type";
import { useState } from "react";
import {
	createCartPublic,
	createCartUser,
	createOrderPublic,
	createOrderUser,
	deletePublicCart,
	deleteUserCart,
	getDetailCartPublic,
	getDetailCartUser,
	postActionCartPublic,
	postActionCartUser,
	preOrderPublic,
	preOrderUser,
} from "../api/shop/cart";
import {
	PromotionDiscountType,
	PromotionGroupType,
	PromotionJson,
} from "@/types/Promotion.type";
import ErrorApi from "@/common/api/Error";
import { ErrorQuantity } from "@/types/Error.type";
import { getDataError } from "../config/apiHandler";
import { Errors } from "@/common/constants/errors";
import { useSession } from "next-auth/react";
import useStoreId from "./useStoreId";
import useBrowerId from "./useBrowerId";
import { mapCouponUpdate } from "@/utils/coupon";
import { postErrorToSystemEvents } from "../api/shop/systemEvents";
import usePayment from "./usePayment";
import {
	PaymentDataSubmit,
	PaymentMethodDetailUpdate,
} from "@/types/Payment.type";
import { quickRegister, updatePreOrder } from "../api/shop/payment";
import { checkoutAction } from "@/actions/payment";
import BaseApi from "@/common/api/BaseApi";
import { InvoiceJson } from "@/types/Invoice.type";
type Props = {
	// isUser?: boolean;
	isOpenPopup?: boolean;
	storeId?: number;
	cartJson?: CartJson;
	typeCart?: "cart" | "order";
	onUpdate?: (cart: CartJson | InvoiceJson | null) => void;
};

type HandleCallback = {
	handleSuccess?: (data?: any) => void;
	handleFailed?: (error: ErrorApi) => void;
};

export type Status = "success" | "failed";

const useCartV2 = ({
	isOpenPopup = false,
	cartJson,
	storeId,
	typeCart,
	onUpdate,
}: Props) => {
	const { data: cus } = useSession();
	const user = cus?.user.user;
	const isUser = !!user;

	const dispatch = useAppDispatch();

	const cartDetail =
		cartJson || useAppSelector((state) => state.CartReducer.cartDetail);

	const { data: storeid, isLoading: isLoadingStoreId } = useStoreId(storeId);

	const store_id = storeId || storeid;

	const {
		data: browserId,
		isError: isErrorBrowserId,
		error: errorBrowserId,
	} = useBrowerId();
	const browser_id = browserId;

	// const {} = usePayment({cartDetail : cartDetail ,customerInfo : user})

	const selectedCartOrder =
		cartDetail?.details?.data?.filter((item) => item.is_use === IsUse.USE) ||
		[];

	// state
	const [loading, setLoading] = useState<boolean>(false);

	const convertPromotionJson = (
		product: ProductJson,
		promotions: PromotionJson[],
		quantity: number,
		cartOrderId?: number
	) => {
		const isUsePromotions = 1;
		let result: ProductCartAddEditJson = {
			item_quantity: quantity,
			product_id: product.id,

			promotions:
				promotions.length > 0
					? promotions.map((p) => {
							return {
								is_use: isUsePromotions,
								item_quantity: quantity,
								product_id: product.id,
								promotion_detail: { ...p },
								promotion_id: p.campaign_info.id,
								sale_promotion_id: p.id,
								code: p.code,
							};
					  })
					: [],
		};
		if (cartOrderId) {
			result.id = cartOrderId;
		}
		return result;
	};

	// handle Error

	const newErrorCartNotFound = () => {
		return new ErrorApi({ errors: ["cart_data_not_found"], statusCode: 500 });
	};

	// process Error

	const handleRefeshDataCart = async () => {
		setLoading(true);
		dispatch(setCartLocalId(null));
		dispatch(setBrowserId(""));
		onUpdate ? onUpdate(null) : dispatch(setCartDetail(null));
	};

	// Init cart

	const switchCart = async () => {
		if (!cartDetail) {
			return;
		}
		try {
			const myObj = await postActionCartUser({
				action: "creator",
				cart_id: cartDetail.id,
				browser_id: browser_id,
				type_sale:
					typeCart === "order"
						? cus?.user?.company?.employee
							? "employee"
							: undefined
						: undefined,
			});
			dispatch(setCartLocalId(myObj.data.id));
			dispatch(setReload());
		} catch (error) {
			const errorData = getDataError(error);
			handleCartError(errorData.errors);
		}
	};

	const getMyCart = async (id: CartId) => {
		const response = isUser
			? await getDetailCartUser(id)
			: await getDetailCartPublic(id, browser_id || "");

		return response;
	};

	const preOrderCart = async ({
		cart_id,
		cartDetailUse,
		chooseProduct,
		chooseQuantity,
		choosePromotion = [],
		chooseCoupon,
		handleSuccess,
		handleFailed,
	}: {
		cart_id: string;
		cartDetailUse?: CartJson;
		chooseProduct?: ProductJson;
		chooseQuantity?: number;
		choosePromotion?: PromotionJson[];
		chooseCoupon?: CartCouponAddJson;
		handleSuccess?: (order_id: CartId) => void;
		handleFailed?: (err: ErrorApi) => void;
	}) => {
		const processPreOrder = async (submitData: CartPreOrderAddEditJson) => {
			try {
				const clientId = browser_id as string;
				const { data: responsePreOrder } = isUser
					? await preOrderUser(submitData)
					: await preOrderPublic(submitData, clientId);
				if (!responsePreOrder.order_id) {
					throw new Error("response not order_id");
				}
				handleSuccess && handleSuccess(responsePreOrder.order_id);
			} catch (error) {
				const dataError = getDataError(error);
				handleFailed && handleFailed(dataError);
				handleCartError(dataError.errors);
			}
		};

		const cart = cartDetailUse ? cartDetailUse : cartDetail;
		if (!chooseProduct) {
			if (cart_id) {
				//     if (cart.coupons.some((coupon) => !coupon.discount)) {
				//       handleFailed?.({
				//         errors: [Cart.ERROR.error_coupon_subtotal_invalid],
				//         statusCode: 400,
				//       });
				//       return;
				//     }
				// let promotionsInvalid : number[] = []
				//     cart.promotions.forEach((promotion) => {
				//       if (!promotion.discount) {
				// 	promotionsInvalid.push(promotion.promotion_id)
				//       }
				//     });
				// if(promotionsInvalid.length){
				// 	handleFailed?.({
				// 		errors: [Cart.ERROR.error_promotion_subtotal_invalid],
				// 		statusCode: 400,
				// 		error_detail: [
				// 		  { error_promotion_invalid: promotionsInvalid },
				// 		],
				// 	  });
				// 	  return;
				// }
				// const listCartItem =
				// 	cart?.details.data.filter((item) => item.is_use === IsUse.USE) || [];

				const propsPrderOrder = {
					store_id: store_id,
					cart_id: cart_id,
					// details: listCartItem.map((item: CartOrderJson) => {
					// 	return {
					// 		item_quantity: item.item_quantity,
					// 		product_id: item.product_id,
					// 		promotions: item.promotions.length > 0 ? item.promotions : [],
					// 		is_use: item.is_use,
					// 	};
					// }),
					// coupons: cart.coupons,
					// promotions: cart.promotions,
				};
				await processPreOrder(propsPrderOrder);
			} else {
				const resCart = await createCart();
				if (resCart) {
					const propsPrderOrder = {
						store_id: store_id,
						cart_id: resCart.id,

						// details: selectedCartOrder.map((item: CartOrderJson) => {
						// 	return {
						// 		item_quantity: item.item_quantity,
						// 		product_id: item.product_id,
						// 		promotions: item.promotions.length > 0 ? item.promotions : [],
						// 		is_use: item.is_use,
						// 	};
						// }),
						// coupons: resCart.coupons,
						// promotions: resCart.promotions,
					};
					await processPreOrder(propsPrderOrder);
				} else {
					handleFailed &&
						handleFailed(
							new ErrorApi({ errors: ["no_cart_data"], statusCode: 500 })
						);
				}
			}
		} else {
			const convertDetailItemJson = (): ProductCartAddEditJson => {
				const result = convertPromotionJson(
					chooseProduct,
					choosePromotion,
					chooseQuantity || 1
				);
				return {
					...result,
					is_use: 1,
				};
			};

			const chooseItem = convertDetailItemJson();
			const submitData: CartPreOrderAddEditJson = {
				store_id: store_id,
				cart_id: cart_id,
				browser_id: browser_id,

				// coupons: chooseCoupon
				// 	? [
				// 			{
				// 				code: chooseCoupon.code,
				// 				id: chooseCoupon.id,
				// 				coupon_campaign_id: chooseCoupon.coupon_campaign_id,
				// 				coupon_detail: chooseCoupon.coupon_detail,
				// 				is_use: IsUse.USE,
				// 			},
				// 	  ]
				// 	: undefined,
				// details: [chooseItem],
			};
			await processPreOrder(submitData);
		}
	};

	async function createCart() {
		try {
			const clientId = browser_id as string;
			const { data } = isUser
				? await createCartUser({
						store_id: store_id,
						type_sale:
							typeCart === "order"
								? cus?.user?.company?.employee
									? "employee"
									: undefined
								: undefined,
				  })
				: await createCartPublic({
						store_id: store_id,
						browser_id: clientId,
				  });

			onUpdate ? onUpdate(data) : dispatch(setCartDetail(data));

			return data;
		} catch (error) {
			const convertError = getDataError(error);

			await postErrorToSystemEvents({
				action: "create_cart",
				company_id: 0,
				details: {
					name: "create_cart_failed",

					location: `BtnAddToCart`,
					message: JSON.stringify(convertError.errors),
					time: new Date().getTime() / 1000,
				},
				event: "error",
				resource_id: user ? user.id.toString() : browser_id || "",
				resource_type: "web",
			});
		}
	}

	async function createOrder() {
		try {
			const clientId = browser_id as string;
			const { data } = isUser
				? await createOrderUser({
						store_id: store_id,
						type_sale:
							typeCart === "order"
								? cus?.user?.company?.employee
									? "employee"
									: undefined
								: undefined,
				  })
				: await createOrderPublic({
						store_id: store_id,
						browser_id: clientId,
						type_sale:
							typeCart === "order"
								? cus?.user?.company?.employee
									? "employee"
									: undefined
								: undefined,
				  });

			onUpdate ? onUpdate(data) : dispatch(setCartDetail(data));

			return data;
		} catch (error) {
			const convertError = getDataError(error);

			await postErrorToSystemEvents({
				action: "create_cart",
				company_id: 0,
				details: {
					name: "create_cart_failed",

					location: `BtnAddToCart`,
					message: JSON.stringify(convertError.errors),
					time: new Date().getTime() / 1000,
				},
				event: "error",
				resource_id: user ? user.id.toString() : browser_id || "",
				resource_type: "web",
			});
		}
	}
	///////////////////////////////////
	// Action

	function promotionUpdateCheck(
		promotions: PromotionJson[],
		items: CartOrderJson[]
	) {
		let promotionUpdate = promotions;
		const promotionItemsCoupon = items.flatMap((data) => data.promotions);
		const promotionCoupon = promotions.find(
			(pro) =>
				pro.group === PromotionGroupType.coupon &&
				pro.discount_type === PromotionDiscountType.PRODUCT
		);
		if (promotionCoupon) {
			const findPro = promotionItemsCoupon.find(
				(pro) =>
					pro.promotion_id === promotionCoupon.campaign_info.id &&
					pro.is_use === IsUse.USE
			);
			if (findPro) {
				promotionUpdate = promotions.map((pro) => {
					if (pro.campaign_info.id === promotionCoupon.campaign_info.id) {
						return {
							...pro,
							code: findPro.code,
						};
					}
					return pro;
				});
			}
		}

		return promotionUpdate;
	}

	const addItemToCart = async (
		data: ProductJson,
		quantity: number,
		promotions = [] as PromotionJson[],
		handleSuccess?: (cart: CartJson) => void,
		handleFail?: (error: ErrorApi) => void
	) => {
		const handlePostActionCart = async (submitData: CartAddEditJson) => {
			try {
				const clientId = browser_id as string;
				const { data: responsePostCart } = isUser
					? await postActionCartUser(submitData, typeCart)
					: await postActionCartPublic(submitData);
				onUpdate
					? onUpdate(responsePostCart)
					: dispatch(setCartDetail(responsePostCart));

				handleSuccess && handleSuccess(responsePostCart);
			} catch (error) {
				const dataError = getDataError(error);
				handleFail?.(dataError);
			}
		};

		const handleAdd = async (cartData: CartJson) => {
			const promotionUpdate = promotionUpdateCheck(
				promotions,
				cartData.details.data
			);

			const result = {
				...convertPromotionJson(data, promotionUpdate, quantity),
				is_use: IsUse.USE,
			};
			const submitData: CartAddEditJson = {
				cart_id: cartData.id,
				action: "add",
				details: [result],
				type_sale:
					typeCart === "order"
						? cus?.user?.company?.employee
							? "employee"
							: undefined
						: undefined,
			};
			await handlePostActionCart(submitData);
		};

		if (cartDetail) {
			handleAdd(cartDetail);
		} else {
			const resCreate = await createCart();
			if (resCreate) {
				handleAdd(resCreate);
			} else {
				handleFail && handleFail(newErrorCartNotFound());
			}
		}
	};

	function convertDetailCartEditJson(
		product: ProductJson,
		promotions: PromotionJson[],
		cartItems: CartOrderJson[],
		quantity: number
	): Partial<ProductCartAddEditJson> {
		const promotionUpdate = promotionUpdateCheck(promotions, cartItems);

		const result: Partial<ProductCartAddEditJson> = {
			...convertPromotionJson(product, promotionUpdate, quantity),
			is_use: IsUse.USE,
		};
		return result;
	}

	const buyNow = async (
		product: ProductJson,
		quantity: number,
		promotions: PromotionJson[],
		handleSuccess?: (orderId: CartId) => void,
		handleFailed?: (error: ErrorApi) => void
	) => {
		if (cartDetail && cartDetail.details.data.length > 0) {
			const isExitedProductInCart = cartDetail.details.data.find(
				(i) => i.product_id === product.id
			);

			const listDisable: Partial<CartOrderJson>[] = cartDetail.details.data.map(
				(i) => {
					const resultFull = convertDetailCartEditJson(
						product,
						promotions,
						cartDetail.details.data,
						quantity
					);
					if (i.product_id === product.id) {
						return {
							id: i.id,
							is_use: 1,
							item_quantity: quantity,
							product_id: i.product_id,
							promotions: resultFull.promotions,
						};
					}
					return {
						id: i.id,
						is_use: 0,
						item_quantity: quantity,
						product_id: i.product_id,
					};
				}
			);
			updateSelectProductInCart({
				id: cartDetail.id,
				cartItems: listDisable,
				handleSuccess(res) {
					!isExitedProductInCart &&
						addItemToCart(
							product,
							quantity,
							promotions,
							(cart) => {
								handleSuccess?.(cart.id);

								// const cartToPreOrder: CartJson = {
								// 	...cart,
								// 	details: {
								// 		...cart.details,
								// 		data: cart.details.data.reduce(
								// 			(crr: CartOrderJson[], prev) => {
								// 				if (prev.product_id === product.id) {
								// 					crr.push({ ...prev, is_use: IsUse.USE });
								// 				} else {
								// 					crr.push({ ...prev, is_use: IsUse.NOT_USE });
								// 				}
								// 				return crr;
								// 			},
								// 			[]
								// 		),
								// 	},
								// };
								// preOrderCart({
								// 	cart_id: cartToPreOrder.id,
								// 	cartDetailUse: cartToPreOrder,
								// 	handleSuccess: handleSuccess,
								// 	handleFailed: (err) => {
								// 		handleFailed && handleFailed(err);
								// 		setLoading(false);
								// 	},
								// });
							},
							handleFailed
						);

					isExitedProductInCart && handleSuccess?.(cartDetail.id);
				},
				handleFailed(error) {
					handleFailed && handleFailed(error);
					setLoading(false);
				},
			});
		} else {
			addItemToCart(
				product,
				quantity,
				promotions,
				(cart) => {
					handleSuccess?.(cart.id);
				},
				handleFailed
			);
		}
	};

	const calcPriceDiscountPromotionBody = (
		priceSell: number,
		promotion: PromotionJson
	) => {
		if (
			priceSell < promotion.req_subtotal ||
			promotion?.type !== Cart.PROMOTION_TYPE.PROCESSING
		) {
			return 0;
		}
		let discount = 0;
		if (promotion.discount_type === "amount") {
			discount = promotion.discount_value;
		} else if (promotion.discount_type === "percent") {
			discount = (priceSell * promotion.discount_value) / 100;
		}

		return discount;
	};

	const addPromotionCart = async (
		promotions: PromotionJson[],
		handleSuccess?: () => void,
		handleError?: (error: string[]) => void
	) => {
		if (!cartDetail) {
			newErrorCartNotFound();
			return;
		}

		const dataUpdate = promotions
			.filter((p) => p.status)
			.map((pro) => {
				return {
					is_use: pro.is_use || 1,
					promotion_detail: pro,
					promotion_id: pro.campaign_info.id,
					sale_promotion_id: pro.id,
				};
			});

		const submitData: CartAddEditJson = {
			action: "promotion",
			cart_id: cartDetail.id,
			details: cartDetail.details.data,
			promotions: dataUpdate,
			type_sale:
				typeCart === "order"
					? cus?.user?.company?.employee
						? "employee"
						: undefined
					: undefined,
		};
		try {
			const clientId = browser_id as string;
			const { data: responsePostCart } = isUser
				? await postActionCartUser(submitData, typeCart)
				: await postActionCartPublic(submitData);
			onUpdate
				? onUpdate(responsePostCart)
				: dispatch(setCartDetail(responsePostCart));

			handleSuccess && handleSuccess();
			// dispatch(setReload());
		} catch (error) {
			const dataError = getDataError(error);
			handleError && handleError(dataError.errors);
		}

		// const newCart: CartJson = {
		//   ...cartDetail,
		//   promotions: promotions.map((pro) => {
		//     return {
		//       discount: calcPriceDiscountPromotionBody(cartDetail.price_sell, pro),
		//       is_use: pro.is_use || 1,
		//       promotion_detail: pro,
		//       promotion_id: pro.campaign_info.id,
		//       sale_promotion_id: pro.id,
		//     };
		//   }),
		// };

		// await updatePriceCartAndSave(newCart);
		// handleSuccess && handleSuccess();
	};

	const addCouponToCart = async ({
		coupon,
		handleSuccess,
		handleFailed,
	}: {
		coupon: CouponCheckJson[];
		handleSuccess?: (res: CouponCheckJson[]) => void;
		handleFailed?: (error: ErrorApi) => void;
	}) => {
		console.log("123");
		try {
			if (!cartDetail) {
				handleFailed && handleFailed(newErrorCartNotFound());
				return;
			}
			const products = cartDetail.details.data;
			const promotionsBody = cartDetail.promotions;

			const { couponBodyUpdate, productsCouponUpdate } = mapCouponUpdate(
				coupon,
				products,
				promotionsBody
			);
			const submitData: CartAddEditJson = {
				action: "update",
				cart_id: cartDetail.id,
				details: productsCouponUpdate,
				type_sale:
					typeCart === "order"
						? cus?.user?.company?.employee
							? "employee"
							: undefined
						: undefined,
			};
			const submitDataBody: CartAddEditJson = {
				action: "promotion",
				cart_id: cartDetail.id,
				promotions: couponBodyUpdate,
				type_sale:
					typeCart === "order"
						? cus?.user?.company?.employee
							? "employee"
							: undefined
						: undefined,
			};

			let responseCart;
			const clientId = browser_id as string;
			if (productsCouponUpdate.length) {
				const { data: response } = isUser
					? await postActionCartUser(submitData, typeCart)
					: await postActionCartPublic(submitData);
				responseCart = response;
			}

			if (couponBodyUpdate.length) {
				const { data: responseBody } = isUser
					? await postActionCartUser(submitDataBody, typeCart)
					: await postActionCartPublic(submitDataBody);
				responseCart = responseBody;
			}

			if (responseCart) {
				onUpdate
					? onUpdate(responseCart)
					: dispatch(setCartDetail(responseCart));

				handleSuccess && handleSuccess(coupon);
			} else {
				handleFailed &&
					handleFailed({ errors: ["not_coupon_post"], statusCode: 406 });
			}
		} catch (error) {
			handleFailed && handleFailed(getDataError(error));
			handleCartError(getDataError(error).errors);
		}
	};

	const updateCartItemQuantity = async ({
		item_id,
		quantity,
		promotions = [],
		handleSuccess,
		handleFailed,
	}: {
		item_id: number;
		quantity: number;
		promotions?: CartPromotion[];
		handleSuccess?: (res: CartOrderJson) => void;
		handleFailed?: (error: ErrorApi) => void;
	}) => {
		if (!cartDetail) {
			handleFailed && handleFailed(newErrorCartNotFound());
			return;
		}
		const cartOrder = cartDetail.details.data.find(
			(item) => item.id === item_id
		);
		if (!cartOrder) {
			handleFailed &&
				handleFailed(
					new ErrorApi({
						errors: ["no_find_cart_detail_item"],
						statusCode: 500,
					})
				);
			return;
		}

		const convertDetailItemJson = (): ProductCartAddEditJson => {
			const result = convertPromotionJson(
				cartOrder.product_json,
				promotions.map((p) => ({ ...p.promotion_detail, code: p.code })),
				quantity,
				cartOrder.id
			);

			return {
				...result,
				is_use: cartOrder.is_use,
			};
		};
		const detailConvert = convertDetailItemJson();
		if (cartOrder.id > 0) {
			const postData: CartAddEditJson = {
				cart_id: cartDetail.id,
				action: "update",
				details: [detailConvert],
				browser_id: browser_id,
				type_sale:
					typeCart === "order"
						? cus?.user?.company?.employee
							? "employee"
							: undefined
						: undefined,
			};

			try {
				const clientId = browser_id as string;

				const { data: responseUpdate } = isUser
					? await postActionCartUser(postData, typeCart)
					: await postActionCartPublic(postData);

				onUpdate
					? onUpdate(responseUpdate)
					: dispatch(setCartDetail(responseUpdate));

				handleSuccess &&
					handleSuccess({
						...cartOrder,
						item_quantity: quantity,
						price_final: 0,
						price_discount: 0,
					});
			} catch (error) {
				const dataError = getDataError(error);
				handleFailed && handleFailed(dataError);
				handleCartError(dataError.errors);
			}
		}
	};

	const updateCartItemVariant = async ({
		item_id,
		is_use,
		product,
		quantity,
		promotions = [],
		handleSuccess,
		handleFailed,
	}: {
		item_id: number;
		product?: ProductJson;
		is_use?: IsUse;
		quantity?: number;
		promotions?: PromotionJson[];
		handleSuccess?: (product?: ProductJson) => void;
		handleFailed?: (error: ErrorApi) => void;
	}) => {
		if (!cartDetail) {
			handleFailed && handleFailed(newErrorCartNotFound());
			return;
		}
		const cartItem = cartDetail.details.data.find(
			(item) => item.id === item_id
		);
		if (!cartItem) {
			handleFailed &&
				handleFailed(
					new ErrorApi({
						errors: ["no_find_cart_order_item_data"],
						statusCode: 500,
					})
				);
			return;
		}

		const productData = product ? product : cartItem.product_json;
		const promotionUpdate = promotionUpdateCheck(
			promotions,
			cartDetail.details.data
		);

		const convertDetailItemJson = (): ProductCartAddEditJson => {
			const result = convertPromotionJson(
				productData,
				promotionUpdate,
				quantity ? quantity : cartItem.item_quantity,
				cartItem.id
			);
			return {
				...result,
				is_use: is_use,
			};
		};

		try {
			const postData: CartAddEditJson = {
				cart_id: cartDetail.id,
				action: "update",
				details: [convertDetailItemJson()],
				browser_id: browser_id,
				type_sale:
					typeCart === "order"
						? cus?.user?.company?.employee
							? "employee"
							: undefined
						: undefined,
			};

			const clientId = browser_id as string;

			const { data: responseUpdate } = isUser
				? await postActionCartUser(postData, typeCart)
				: await postActionCartPublic(postData);
			onUpdate
				? onUpdate(responseUpdate)
				: dispatch(setCartDetail(responseUpdate));

			// dispatch(setReload());
			handleSuccess && handleSuccess(product);
		} catch (error) {
			const dataError = getDataError(error);
			handleFailed && handleFailed(dataError);
			handleCartError(dataError.errors);
		}
	};

	const deleteItemInCart = async (
		item_id: number,
		item_ids?: number[],
		reload: boolean = true
	) => {
		let status: Status = "success";
		setLoading(true);

		if (!cartDetail) {
			newErrorCartNotFound();

			status = "failed";
			// handleCartError(["cart_data_not_exist"]);

			return status;
		}

		const selectCartItem = cartDetail.details.data.find(
			(item) => item.id === item_id
		);

		if (!selectCartItem) {
			status = "failed";
			handleCartError(["cart_item_not_found"]);
			return status;
		}
		let selectedCartItems: CartOrderJson[] = [];
		if (item_ids) {
			selectedCartItems = selectedCartOrder.filter((item) =>
				item_ids.includes(item.id)
			);
		} else {
			selectedCartItems = [{ ...selectCartItem }];
		}
		try {
			const submitData: CartAddEditJson = {
				action: "delete",
				cart_id: cartDetail.id,
				details: selectedCartItems.map((item) => ({ id: item.id })),
				type_sale:
					typeCart === "order"
						? cus?.user?.company?.employee
							? "employee"
							: undefined
						: undefined,
			};
			const clientId = browser_id as string;

			const { data: response } = isUser
				? await postActionCartUser(submitData, typeCart)
				: await postActionCartPublic(submitData);
			clientId;
			status = "success";
			if (response) {
				onUpdate ? onUpdate(response) : dispatch(setCartDetail(response));
			}
			if (reload) {
				// onUpdate ? onUpdate(null) : dispatch(setCartDetail(response));
				dispatch(setReload());
			}
		} catch (error) {
			status = "failed";
			handleCartError(getDataError(error).errors);
		}

		setLoading(false);
		return status;
	};

	const deleteProducstInCart = async (
		productsDelete: CartOrderJson[],
		handleFailed?: (error: ErrorApi) => void
	) => {
		if (!cartDetail) {
			handleFailed && handleFailed(newErrorCartNotFound());
			return;
		}
		if (productsDelete.length > 0) {
			const submitData: CartAddEditJson = {
				action: "delete",
				cart_id: cartDetail.id,
				details: productsDelete.map((item) => ({ id: item.id })),
				type_sale:
					typeCart === "order"
						? cus?.user?.company?.employee
							? "employee"
							: undefined
						: undefined,
			};
			try {
				const clientId = browser_id as string;

				const { data: response } = isUser
					? await postActionCartUser(submitData, typeCart)
					: await postActionCartPublic(submitData);

				onUpdate ? onUpdate(response) : dispatch(setCartDetail(response));

				// dispatch(setReload());
			} catch (error) {
				handleFailed && handleFailed(getDataError(error));
			}
		}
	};

	const updateSelectProductInCart = async ({
		id,
		cartItems,
		handleSuccess,
		handleFailed,
	}: {
		id: CartId;
		cartItems: Partial<CartOrderJson>[];
		handleSuccess?: (res: CartJson) => void;
		handleFailed?: (error: ErrorApi) => void;
	}) => {
		dispatch(setCartLoading(true));

		const body: CartAddEditJson = {
			action: "update",
			cart_id: id,
			details: cartItems,
			type_sale:
				typeCart === "order"
					? cus?.user?.company?.employee
						? "employee"
						: undefined
					: undefined,
		};

		try {
			const clientId = browser_id as string;

			const myObj = isUser
				? await postActionCartUser(body, typeCart)
				: await postActionCartPublic(body);
			// dispatch(setReload());

			onUpdate ? onUpdate(myObj.data) : dispatch(setCartDetail(myObj.data));

			handleSuccess && handleSuccess(myObj.data);
		} catch (error) {
			handleFailed && handleFailed(getDataError(error));
		} finally {
			dispatch(setCartLoading(false));
		}
	};

	const removeCouponFromCart = async ({
		coupon_list,
		handleSuccess,
		handleFailed,
	}: {
		coupon_list: CartPromotion[];
		handleSuccess?: () => void;
		handleFailed?: (error: ErrorApi) => void;
	}) => {
		try {
			if (!cartDetail) {
				handleFailed && handleFailed(newErrorCartNotFound());
				return;
			}
			const clientId = browser_id as string;

			const couponItem = coupon_list.filter(
				(cou) =>
					cou.promotion_detail.discount_type === PromotionDiscountType.PRODUCT
			);
			const couponBody = coupon_list.filter(
				(cou) =>
					cou.promotion_detail.discount_type === PromotionDiscountType.CART
			);

			let response;
			if (couponItem.length) {
				const dataUpdate = cartDetail.details.data.filter((data) =>
					data.promotions.some((pro) =>
						couponItem.some((cou) => cou.code === pro.code)
					)
				);

				const body = dataUpdate.map((data) => ({
					...data,
					promotions: data.promotions.filter((pro) =>
						couponItem.some((cou) => cou.code !== pro.code)
					),
				}));
				const dataSubmit: CartAddEditJson = {
					action: "update",
					cart_id: cartDetail.id,
					details: body,
					type_sale:
						typeCart === "order"
							? cus?.user?.company?.employee
								? "employee"
								: undefined
							: undefined,
				};

				response = isUser
					? await postActionCartUser(dataSubmit, typeCart)
					: await postActionCartPublic(dataSubmit);
			}

			if (couponBody.length) {
				const dataSubmit: CartAddEditJson = {
					action: "promotion",
					cart_id: cartDetail.id,
					promotions: cartDetail.promotions.filter(
						(item) => !couponBody.map((cou) => cou.id).includes(item.id)
					),
				};
				response = isUser
					? await postActionCartUser(dataSubmit, typeCart)
					: await postActionCartPublic(dataSubmit);
			}

			if (response) {
				onUpdate
					? onUpdate(response.data)
					: dispatch(setCartDetail(response.data));

				handleSuccess && handleSuccess();
			}
		} catch (error) {
			const dataError = getDataError(error);
			handleFailed && handleFailed(dataError);
			handleCartError(dataError.errors);
		}
	};

	const updateModifyCart = async ({
		handleFailed,
		handleSuccess,
	}: HandleCallback) => {
		if (!cartDetail) {
			handleFailed && handleFailed(newErrorCartNotFound());
			return;
		}
		const submitData: CartAddEditJson = {
			action: "fetch",
			cart_id: cartDetail.id,
			browser_id: browser_id,
			type_sale:
				typeCart === "order"
					? cus?.user?.company?.employee
						? "employee"
						: undefined
					: undefined,
		};
		try {
			const clientId = browser_id as string;

			isUser
				? await postActionCartUser(submitData)
				: await postActionCartPublic(submitData);

			handleSuccess && handleSuccess();
		} catch (error) {
			handleFailed && handleFailed(getDataError(error));
		}
	};

	const updatePromotionCart = async ({
		data,
		handleSuccess,
		handleFailed,
	}: HandleCallback & {
		data: CartPromotion[];
	}) => {
		if (!cartDetail) {
			handleFailed && handleFailed(newErrorCartNotFound());
			return;
		}

		try {
			const submitData: CartAddEditJson = {
				action: "promotion",
				cart_id: cartDetail.id,
				promotions: data,
				type_sale:
					typeCart === "order"
						? cus?.user?.company?.employee
							? "employee"
							: undefined
						: undefined,
			};
			const clientId = browser_id as string;

			const myObj = isUser
				? await postActionCartUser(submitData)
				: await postActionCartPublic(submitData);

			onUpdate ? onUpdate(myObj.data) : dispatch(setCartDetail(myObj.data));

			handleSuccess && handleSuccess(myObj.data);
			// dispatch(setReload());
		} catch (error) {
			const dataError = getDataError(error);
			handleFailed && handleFailed(dataError);
			handleCartError(dataError.errors);
		}
	};

	const updateProductRemainingInCart = async ({
		dataError,
		handleFinish,
	}: {
		dataError: ErrorQuantity[];
		handleFinish?: (status: "success" | "failed") => void;
	}) => {
		if (!cartDetail) {
			newErrorCartNotFound();
			handleFinish && handleFinish("failed");
			return;
		}
		const dataRemainigCartItems = cartDetail.details.data.reduce(
			(crr: CartOrderJson[], item) => {
				const itemRemainingData = dataError.find(
					(i) => i.id === item.product_id
				);
				if (itemRemainingData) {
					const isInStock = itemRemainingData.item_quantity > 0;
					crr.push({
						...item,
						is_use: isInStock ? 1 : 0,
						item_quantity: isInStock
							? itemRemainingData.item_quantity
							: item.item_quantity,
					});
				}
				return crr;
			},
			[]
		);
		if (dataRemainigCartItems.length > 0) {
			dispatch(setProductOutOfStockInCart(dataRemainigCartItems));
			await updateSelectProductInCart({
				id: cartDetail.id,
				cartItems: dataRemainigCartItems,
			});
		}
	};

	const pricePromotionBody = (promotions: CartPromotion[]): number => {
		let result = 0;
		for (let i = 0; i < promotions.length; i++) {
			if (promotions[i].discount && Number(promotions[i].discount) > 0) {
				result += Number(promotions[i].discount);
			}
		}
		return result;
	};

	const updateDiscountCart = async (props: CartUpdateDiscount) => {
		if (!cartDetail) {
			return null;
		}
		const submitData: CartAddEditJson = {
			action: "update_discount",
			cart_id: cartDetail.id,
			type_sale:
				typeCart === "order"
					? cus?.user?.company?.employee
						? "employee"
						: undefined
					: undefined,
			...props,
		};

		try {
			const clientId = browser_id as string;

			const myObj = isUser
				? await postActionCartUser(submitData)
				: await postActionCartPublic(submitData);
			onUpdate ? onUpdate(myObj.data) : dispatch(setCartDetail(myObj.data));
			return myObj.data;
		} catch (error) {
			return null;
		}
	};

	const updateCustomerOrder = async (props: CartUpdateCustomer) => {
		if (!cartDetail) {
			throw new Error("No cart detail");
		}
		const submitData: CartAddEditJson = {
			action: "update_customer",
			cart_id: cartDetail.id,
			type_sale:
				typeCart === "order"
					? cus?.user?.company?.employee
						? "employee"
						: undefined
					: undefined,
			...props,
		};

		const myObj = await postActionCartUser(submitData, "order");

		onUpdate ? onUpdate(myObj.data) : dispatch(setCartDetail(myObj.data));
		return myObj.data;
	};
	///////////////////////////////
	// handle data + error
	const handleCartError = async (errors: string[]) => {
		dispatch(setCartGlobalErrors(errors));
		const errorWarehouse = [
			Errors.error_warehouse_invalid,
			Errors.error_store_warehouse_invalid,
			Errors.error_warehouse_id_invalid,
		];
		if (errors.some((err) => errorWarehouse.includes(err))) {
			dispatch(setPopupId(Cart.POPUP.cart_error_popup));
			await handleRefeshDataCart();
		}

		if (isOpenPopup) {
			dispatch(setPopupId(Cart.POPUP.system_error));
		}
	};

	async function handleDeleteCart(cart_id: string) {
		const res = isUser
			? deleteUserCart({ cart_id })
			: deletePublicCart({ cart_id, browser_id });
		return res;
	}

	async function handleSubmit(
		orderDetail: CartJson,
		formData: PaymentDataSubmit
	) {
		const dataSubmit = { ...formData };
		if (
			(orderDetail.debt > 0 && formData.paymentMethod === "transfer") ||
			(orderDetail.debt > 0 && formData.paymentMethod === "vnpay")
		) {
			const payment: PaymentMethodDetailUpdate = {
				amount: orderDetail.debt,
				method: formData.paymentMethod,
			};
			await updatePreOrder(
				formData.order_id,
				{
					action: "payment",
					payments: [payment],
				},
				isUser
			);
		}
		const { data } = await checkoutAction(dataSubmit, isUser);
		return data;
	}

	async function completeInvoice(id: string) {
		console.log(id);
	}

	async function handleCheckout(formData: Omit<PaymentDataSubmit, "id">) {
		let orderId: CartPayJson = {
			code: "",
			id: "",
			url_payment: "",
			invoice_id: "",
		};
		if (!cartDetail) {
			throw new ErrorApi({ errors: ["cart_data_not_found"], statusCode: 500 });
		}
		await preOrderCart({
			cart_id: cartDetail.id,
			handleSuccess: async (order_id) => {
				try {
					const res = await handleSubmit(cartDetail, {
						...formData,
						order_id: order_id,
					});
					await handleDeleteCart(cartDetail.id);
					orderId = res;
					if (res.invoice_id) {
						completeInvoice(res.invoice_id);
					}
				} catch (error) {
					const err = BaseApi.handleError(error);
					throw new Error(err.errors?.[0]);
				}
			},
		});

		return orderId;
	}

	async function updateInfoOrder() {
		if (!cartDetail) {
			throw new ErrorApi({ errors: ["cart_data_not_found"], statusCode: 500 });
		}

		const res = await postActionCartUser({
			action: "update",
			cart_id: cartDetail.id,
		});
	}

	return {
		loading: loading,
		switchCart,
		updateCartItemQuantity,
		updateCartItemVariant,
		deleteItemInCart,
		getMyCart,
		preOrderCart,
		addItemToCart,
		buyNow,
		createCart,
		addCouponToCart,
		removeCouponFromCart,
		convertPromotionJson,
		deleteProducstInCart,
		updateSelectProductInCart,
		updateModifyCart,
		updateProductRemainingInCart,
		updatePromotionCart,
		pricePromotionBody,
		addPromotionCart,
		handleRefeshDataCart,
		calcPriceDiscountPromotionBody,
		handleCheckout,
		handleDeleteCart,
		updateDiscountCart,
		createOrder,
		updateCustomerOrder,
	};
};

// gia theo promotion  update quantity
// update price promotion effect in cart
// tính payemntPriceFianl từ data sạch ( key trong cartJson or các item trong cart)
// post coupon lên update cart

export default useCartV2;
