import { callApiV2 } from "@/lib/config/Api";
import {
	CalcPricePromotionJson,
	CalcPriceProps,
	CartAddEditJson,
	CartCollectionJson,
	CartCreateJson,
	CartDeleteJson,
	CartId,
	CartJson,
	CartOrderCollectionJson,
	CartPreOrderAddEditJson,
	CartPreOrderJson,
	QuantityRemaining,
} from "@/types/Cart.type";
import { InvoiceJson } from "@/types/Invoice.type";
import { createOrderProps, OrderJson } from "@/types/Orders.type";
import Helper from "@/utils/helper";

export async function createCartPublic(data: CartCreateJson) {
	let postData = {
		...data,
	};
	const response = await callApiV2<OrderJson>(
		`/orders/cart/public/add`,
		"post",
		{
			data: postData,
		}
	);

	return response;
}

export async function createCartUser(data: CartCreateJson) {
	let postData = Helper.convertParams({
		...data,
	});

	const response = await callApiV2<OrderJson>(`/orders/cart/add`, "post", {
		data: postData,
	});

	return response;
}

export async function saveCartPublic(cart: CartJson) {
	const response = await callApiV2<CartJson>(
		`/orders/cart/public/update/${cart.id}`,
		"put",
		{
			data: {
				action: "save_cart",
				cart_detail: cart,
				browser_id: cart.browser_id,
			},
		}
	);

	return response;
}
export async function saveCartUser(cart: CartJson) {
	const response = await callApiV2<CartJson>(
		`/orders/cart/update/${cart.id}`,
		"put",
		{
			data: {
				action: "save_cart",

				cart_detail: cart,
			},
		}
	);

	return response;
}

export async function postActionCartPublic(data: CartAddEditJson) {
	let dataProps: any = { ...data };
	let postData = {
		...dataProps,
	};
	const response = await callApiV2<OrderJson>(
		`/orders/cart/public/update/${data.cart_id}`,
		"put",
		{ data: { ...postData } }
	);

	return response;
}

export async function postActionCartUser(
	data: CartAddEditJson,
	typeCart?: "order" | "cart"
) {
	const type = typeCart ?  typeCart :  data.type_sale ? "order" : "cart";
	let dataProps: any = { ...data };
	if (data.action !== "creator") {
		delete dataProps.cart_id;
	}
	let postData = Helper.convertParams({
		...dataProps,
	});
	const response = await callApiV2<OrderJson>(
		`/orders/${type === "cart" ? "cart/update/" : "cart/order/"}${data.cart_id
		}`,
		"put",
		{ data: postData }
	);

	return response;
}

export async function postActionCartAdmin(data: CartAddEditJson) {
	let dataProps: any = { ...data };
	delete dataProps.cart_id;
	let postData = {
		...dataProps,
	};
	const response = await callApiV2<CartJson>(
		`/orders/cart/admin/${data.cart_id}`,
		"put",
		{ data: postData }
	);

	return response;
}

export async function getListMyCart(params?: {
	sort_type: string;
	limit?: number;
	type_sale?: "employee";
}) {
	const dataParams = Helper.convertParams(params);
	const response = await callApiV2<CartCollectionJson>(
		`/orders/cart/mycart`,
		"get",
		{ params: dataParams }
	);

	return response;
}

export async function getListMyCartPublic(
	browserId: string,
	params?: { sort_type: string; limit: number }
) {
	const response = await callApiV2<CartCollectionJson>(
		`/orders/cart/public/mycart?browser_id=${browserId}`,
		"get",
		{
			params,
		}
	);

	return response;
}

export async function getDetailCartUser(id: CartId) {
	const response = await callApiV2<CartJson>(`/orders/cart/${id}`, "get", {
		timeout: 10 * 1000,
	});

	return response;
}

export async function getDetailCartPublic(id: CartId, browserId: string) {
	const response = await callApiV2<CartJson>(
		`/orders/cart/public/${id}?browser_id=${browserId}`,
		"get",
		{ timeout: 10 * 1000 }
	);

	return response;
}

export async function getMyOrder() {
	const response = await callApiV2<CartOrderCollectionJson>(
		`/orders/cart/myorder`,
		"get"
	);

	return response;
}

export async function getCartBrowser() {
	const response = await callApiV2<string>(
		`/orders/cart/public/browserid`,
		"get"
	);

	return response;
}

export async function deletePublicCart(data: CartDeleteJson) {
	let dataProps: CartDeleteJson = { ...data };
	let postData = {
		...dataProps,
	};

	const response = await callApiV2<any>(
		`/orders/cart/public/delete/${data.cart_id}`,
		"put",
		{ data: postData }
	);

	return response;
}

export async function deleteUserCart(data: CartDeleteJson) {
	const response = await callApiV2<any>(
		`/orders/cart/delete/${data.cart_id}`,
		"put"
	);
	return response;
}

export async function preOrderPublic(
	data: CartPreOrderAddEditJson,
	browser_id: string
) {
	let postData = {
		...data,
	};

	const response = await callApiV2<CartPreOrderJson>(
		`/orders/cart/public/order`,
		"post",
		{ data: { ...postData, browser_id: browser_id } }
	);

	return response;
}

export async function preOrderUser(data: CartPreOrderAddEditJson) {
	let postData = {
		...data,
	};
	const response = await callApiV2<CartPreOrderJson>(
		`/orders/cart/order`,
		"post",
		{ data: { ...postData } }
	);
	return response;
}

export async function createOrderUser(data: createOrderProps) {
	let postData = Helper.convertParams({
		...data,
	});

	const response = await callApiV2<OrderJson>(`/orders/cart/order`, "post", {
		data: postData,
	});

	return response;
}

export async function createOrderPublic(data: createOrderProps) {
	let postData = Helper.convertParams({
		...data,
	});

	const response = await callApiV2<OrderJson>(
		`/orders/cart/public/order`,
		"post",
		{
			data: postData,
		}
	);

	return response;
}

export async function calculatorPriceFromPromotion(data: CalcPriceProps) {
	let postData = {
		...data,
	};

	const response = await callApiV2<CalcPricePromotionJson>(
		`/orders/cart/public/calucalator`,
		"post",
		{ data: postData }
	);

	return response;
}

export async function getDataStockRemainingProduct(productIds: number[]) {
	const res = await callApiV2<QuantityRemaining[]>(
		"/inventories/public/quantity",
		"get",
		{
			params: {
				product_ids: productIds,
			},
		}
	);

	return res;
}
