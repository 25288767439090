import { callApiV2 } from "@/lib/config/Api";

export type ErrorType =
	| "global"
	| "checkouts"
	| "cart"
	| "products"
	| "home"
	| "collections"
	| "brands"
	| "pages"
	| "search"
	| "vnpay"
	| "web"
	| "pos";

interface ErrorSystemEvent {
	company_id: number;
	resource_id: string; // brower id hoac cus id
	resource_type: ErrorType; // page nào
	action: string; // mã lỗi
	event: "error";
	details: {
		time: number;
		tracing?: string | unknown;
		message: string;
		location: string;
		ip?: string;
		name?: string;
	}; // chi tiết của lỗi đó, nhập json lỗi ở đây
}
export async function postErrorToSystemEvents(body: ErrorSystemEvent) {
	const data = { ...body, details: JSON.stringify(body.details) };

	const response = await callApiV2<any>("/systemevents/public", "post", {
		data: {
			data: data,
		},
	});
	return response;
}
